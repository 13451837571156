import { getUniversalHelpers } from "../../helpers";
import { CalendarEntry } from "@ollie-sports/models";
import { SimpleQuery } from "@ollie-sports/firebase-lift";
import moment from "moment-timezone";
import _ from "lodash";
import { MOMENT_DATE_TIME_FORMAT } from "../../constants/Time";
import { filterAndOrderCalendarEvents } from "./utils/filterEvents";

export async function calendarEntry__client__past(p: { teamIds: string[]; orgIds: string[]; nextQuery?: any }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  //Have to add 24 hours b/c we are querying on datetime, not a timestamp
  const cutoffEndDateTime = moment().add(1, "day").format(MOMENT_DATE_TIME_FORMAT);

  const orgEventsProm = p.orgIds.length
    ? h.CalendarEntry.multiQuery({
        queries: p.orgIds.map(orgId => {
          let q: SimpleQuery<CalendarEntry> = {
            where: [
              { orgEventOrgId: ["==", orgId] },
              { teamId: ["==", ""] },
              {
                endDateTime: ["<=", cutoffEndDateTime]
              },
              {
                endDateTime: [">", moment().subtract(3, "month").format(MOMENT_DATE_TIME_FORMAT)]
              }
            ],
            orderBy: [{ dir: "desc", pathObj: { endDateTime: true } }]
          };
          return q;
        })
      })
    : null;
  const orgEvents = orgEventsProm ? (await orgEventsProm).docs : [];

  if (p.nextQuery) {
    const result = await h.CalendarEntry.query(p.nextQuery);

    return {
      items: result.docs,
      nextQuery: result.nextQuery
    };
  } else if (p.teamIds.length > 1) {
    const i = await h.CalendarEntry.multiQuery({
      queries: p.teamIds.map(teamId => {
        let q: SimpleQuery<CalendarEntry> = {
          where: [
            { teamId: ["==", teamId] },
            {
              endDateTime: ["<=", cutoffEndDateTime]
            },
            {
              endDateTime: [">", moment().subtract(3, "month").format(MOMENT_DATE_TIME_FORMAT)]
            }
          ],
          orderBy: [{ dir: "desc", pathObj: { endDateTime: true } }]
        };
        return q;
      })
    });
    let items = [...i.docs, ...orgEvents];
    return {
      items: filterAndOrderCalendarEvents({ data: items, type: "past" }),
      nextQuery: undefined
    };
  } else if (p.teamIds.length === 1) {
    const data = await h.CalendarEntry.query({
      where: [
        { teamId: ["==", p.teamIds[0]] },
        {
          endDateTime: ["<=", cutoffEndDateTime]
        }
      ],
      limit: 25,
      orderBy: [{ dir: "desc", pathObj: { endDateTime: true } }]
    });

    return {
      items: filterAndOrderCalendarEvents({ data: [...data.docs, ...orgEvents], type: "past" }),
      nextQuery: data.nextQuery
    };
  } else {
    return {
      items: [],
      nextQuery: undefined
    };
  }
}

// i18n certified - complete
