import { EventFilterSettings } from "@ollie-sports/models";

export function getQueryFragmentToFilterEvents(filterSettings: EventFilterSettings) {
  let filterQuery = "";
  if (Object.keys(filterSettings.eventTeamIds || {}).length) {
    filterQuery += `and t.item->>'id' = any ('{${Object.keys(filterSettings.eventTeamIds || {})
      .map(v => `${v}`)
      .join(`,`)}}')`;
  }

  if (Object.keys(filterSettings.eventBirthYears || {}).length) {
    filterQuery += `and t.item->>'birthYear' = any ('{${Object.keys(filterSettings.eventBirthYears || {})
      .map(v => `${v}`)
      .join(`,`)}}')`;
  }

  if (Object.keys(filterSettings.eventGenders || {}).length) {
    filterQuery += `and t.item->>'gender' = any ('{${Object.keys(filterSettings.eventGenders || {})
      .map(v => `${v}`)
      .join(`,`)}}')`;
  }

  if (Object.keys(filterSettings.eventTypes || {}).length) {
    filterQuery += `and c.item->>'calendarEntryType' = any ('{${Object.keys(filterSettings.eventTypes || {})
      .map(v => `${v}`)
      .join(`,`)}}')`;
  }

  const isAllSquadFilterEnabled = !!filterSettings.squadTypes?.all;
  const isAtLeastOneSquadThatIsNotAllFilterEnabled = !!Object.keys(filterSettings.squadTypes || {}).find(s => s !== "all");

  if (isAllSquadFilterEnabled && isAtLeastOneSquadThatIsNotAllFilterEnabled) {
    filterQuery += `and (c.item->>'squad' = any ('{${Object.keys(filterSettings.squadTypes || {})
      .map(v => `${v}`)
      .join(`,`)}}')${!!filterSettings.squadTypes?.all ? ` or c.item->>'squad' is null` : ""})`;
  } else if (isAllSquadFilterEnabled) {
    filterQuery += `and c.item->>'squad' is null`;
  } else if (isAtLeastOneSquadThatIsNotAllFilterEnabled) {
    filterQuery += `and c.item->>'squad' = any ('{${Object.keys(filterSettings.squadTypes || {})
      .map(v => `${v}`)
      .join(`,`)}}')`;
  }

  return filterQuery;
}
