import { getUniversalHelpers } from "../../helpers";
import { CalendarEntry__PlayerAttendance } from "@ollie-sports/models";

export async function calendarEntry__client__setPlayerAttendanceComing(p: {
  calendarEntryId: string;
  playerId: string;
  attendance: Partial<CalendarEntry__PlayerAttendance>;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.CalendarEntry.update({
    id: p.calendarEntryId,
    doc: { attendance: { [p.playerId]: p.attendance } }
  });
}

// i18n certified - complete
