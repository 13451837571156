import { CalendarEntry, GoogleAddress, OrgId, TeamId } from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";

export async function calendarEntry__server__recentLocations(p: { teamId?: TeamId; orgId?: OrgId }): Promise<GoogleAddress[]> {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool } = getServerHelpers();

  if (!p.teamId && !p.orgId) {
    return [];
  }

  const query = `select distinct item -> 'location' as location
  from mirror_calendarentry
  where item ->> 'location' is not null
    ${p.teamId ? `and item ->> 'teamId' = '${p.teamId}'` : ""}
    ${p.orgId ? `and item ->> 'orgId' = '${p.orgId}'` : ""}
    and to_timestamp(cast(item ->> 'createdAtMS' as numeric) / 1000) > (now() - interval '1 years');`;

  return (await getAppPgPool().query(query)).rows.map(a => a.location as GoogleAddress);
  // SERVER_ONLY_TOGGLE
}

calendarEntry__server__recentLocations.auth = async (req: express.Request) => {
  await validateToken(req);
};

// i18n certified - complete
