import { getServerHelpers } from "../../helpers";
import { CalendarEntry, EventFilterSettings, Org, OrgId, Team } from "@ollie-sports/models";
import _ from "lodash";
import moment from "moment-timezone";
import { validateSelfAccountId, validateToken } from "../../internal-utils/server-auth";
import { MOMENT_DATE_TIME_FORMAT } from "../../constants/Time";
import { getQueryFragmentToFilterEvents } from "./utils/getQueryFragmentToFilterEvents";
import { filterAndOrderCalendarEvents } from "./utils/filterEvents";

export async function calendarEntry__server__pastForOrg(p: {
  selfAccountId: string;
  defaultOrgId?: string;
  timezone: string;
  nextQuery?: number;
  orgs: Org[];
}) {
  const { appOllieFirestoreV2: h } = getServerHelpers();
  // SERVER_ONLY_TOGGLE
  const filterSettings = await h.AccountPrivate.getDoc(p.selfAccountId).then(a => a?.settings?.eventFilters || {});

  const orgIdToUse = filterSettings.orgIdActivated || p.defaultOrgId;

  if (!orgIdToUse) {
    throw new Error("No org activated on user event filters");
  }
  const org = p.orgs.find(o => (o.id = orgIdToUse));
  const pageSize = 50;
  const offSetMultiplier = p.nextQuery ? p.nextQuery : 0;

  const filterQuery = getQueryFragmentToFilterEvents(filterSettings);

  //TODO: Fix time zone bug Note: Have to add 24 hours to the query cutoff b/c we store strings, not timestamps
  let query = `
  select *
  from (select c.item calendarEntry,
               t.item team
        from mirror_calendarentry c  left join mirror_team t
        on c.item->>'teamId' = t.id
          where (t.item->>'orgId' = $1 or c.item->>'orgEventOrgId' = $1)
          and to_date(substr(c.item->>'endDateTime',0,11), 'YYYY-MM-DD') < NOW()
          ${filterQuery}) aa
  where true
  order by calendarEntry->>'startDateTime' desc
  offset ${pageSize * offSetMultiplier}
  limit ${pageSize};
  `;

  let r1 = await getServerHelpers().getAppPgPool().query(query, [orgIdToUse]);

  return {
    items: filterAndOrderCalendarEvents({
      data: r1.rows.map(r => r.calendarentry),
      timezone: p.timezone,
      type: "past"
    }),
    teams: _(r1.rows)
      .map(r => r.team)
      .compact()
      .uniqBy(t => t.id)
      .value() as Team[],
    nextQuery: offSetMultiplier + 1
  };

  // SERVER_ONLY_TOGGLE
}

calendarEntry__server__pastForOrg.auth = async (req: any) => {
  await validateSelfAccountId(req, req.body.selfAccountId);
};

// i18n certified - complete
