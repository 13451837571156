import { BifrostSubscription } from "@ollie-sports/react-bifrost";
import { CalendarEntry, EventFilterSettings } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { SimpleQuery } from "@ollie-sports/firebase-lift";
import _ from "lodash";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";
import { MOMENT_DATE_TIME_FORMAT } from "../../constants/Time";
import moment from "moment-timezone";
import { mapBifrostSubscription } from "../../internal-utils/bifrostHelpers";
import { filterAndOrderCalendarEvents } from "./utils/filterEvents";

export function calendarEntry__client__byTeamsUpcomingSubscription(p: {
  teamIds: string[];
  orgIds: string[];
}): BifrostSubscription<CalendarEntry[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return mapBifrostSubscription(
    firestoreLiftQuerySubToBifrostSub(
      h.CalendarEntry.multiQuerySubscription({
        queries: p.teamIds
          .map(teamId => {
            const pendingQuery: SimpleQuery<CalendarEntry> = {
              where: [
                { teamId: ["==", teamId] },
                {
                  endDateTime: [">", moment().subtract(1, "day").format(MOMENT_DATE_TIME_FORMAT)]
                }
              ],
              orderBy: [{ dir: "desc", pathObj: { endDateTime: true } }]
            };
            return pendingQuery;
          })
          .concat(
            p.orgIds.map(orgId => {
              const pendingQuery: SimpleQuery<CalendarEntry> = {
                where: [
                  { teamId: ["==", ""] },
                  { orgEventOrgId: ["==", orgId] },
                  {
                    endDateTime: [">", moment().subtract(1, "day").format(MOMENT_DATE_TIME_FORMAT)]
                  }
                ],
                orderBy: [{ dir: "desc", pathObj: { endDateTime: true } }]
              };
              return pendingQuery;
            })
          )
      })
    ),
    a => filterAndOrderCalendarEvents({ data: a, type: "upcoming" })
  );
}

// i18n certified - complete
