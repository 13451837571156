import { CalendarEntry, isPostStartedSoccerGame, SoccerGame, Team, TeamId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";
import { BatchTask } from "@ollie-sports/firebase-lift";
import { common__generateTeamIdWithSquad } from "..";
import { ObjectKeys } from "../..";
import { fetchDefaultGameForTeam } from "../soccerGame/helpers/fetchDefaultGameForTeam";
import _ from "lodash";

export async function calendarEntry__server__update(p: {
  calendarEntryPartial: Partial<CalendarEntry> & { id: string };
  didSquadChange: boolean;
  teamId: TeamId;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const batchTasks: BatchTask[] = [];
  batchTasks.push(
    await h.CalendarEntry.update({ id: p.calendarEntryPartial.id, doc: p.calendarEntryPartial }, { returnBatchTask: true })
  );
  if (p.didSquadChange) {
    const [soccerGamesData, team] = await Promise.all([
      h.SoccerGame.query({ where: [{ calendarEntryId: ["==", p.calendarEntryPartial.id] }] }),
      h.Team.getDoc(p.teamId)
    ]);

    const soccerGame = soccerGamesData.docs[0];
    if (!team) {
      throw new Error("The specified team id could not be found! Unable to update calendarEntry");
    }

    if (soccerGamesData.docs.length > 1) {
      console.warn("More than one soccer game detected for calendarEntryId " + p.calendarEntryPartial.id);
    }

    if (soccerGame) {
      function getFauxDefaultGame() {
        return fetchDefaultGameForTeam({
          calendarEntryId: "does-not-matter",
          recorderAccountId: "does-not-matter",
          squad: p.calendarEntryPartial.squad,
          teamId: p.teamId
        });
      }

      const soccerGameUpdates: Partial<SoccerGame> = { squad: p.calendarEntryPartial.squad ?? h._MagicDeleteValue };

      if (soccerGame.gameStage === "started") {
        //ADD new players (but don't remove)
        const defaultGame = await getFauxDefaultGame();
        soccerGameUpdates.roster = soccerGame.roster || {};
        Object.keys(defaultGame.roster || {}).forEach(playerId => {
          soccerGameUpdates.roster![playerId] = true;
        });
      } else if (soccerGame.gameStage === "pre-setup" || soccerGame.gameStage === "setup") {
        const defaultGame = await getFauxDefaultGame();

        soccerGameUpdates.gameStage = "pre-setup"; //Gotta revert to pre-set up to keep values harmonious with each other...
        soccerGameUpdates.roster = defaultGame.roster;
        soccerGameUpdates.starterIdToPosition = defaultGame.starterIdToPosition;
        soccerGameUpdates.clockMode = defaultGame.clockMode;
        soccerGameUpdates.startingFormation = defaultGame.startingFormation;
        soccerGameUpdates.statMode = defaultGame.statMode;
        soccerGameUpdates.maxFieldPlayers = defaultGame.maxFieldPlayers;
      } else if (soccerGame.gameStage === "ended") {
        batchTasks.push(
          await h.Team.update(
            { id: soccerGame.teamId, doc: { lastTimeMajorGameUpdateWasMadeMS: Date.now() + 1000 * 30 } },
            { returnBatchTask: true }
          )
        );

        console.log("UPDATING LASTTIMEMAJORGAMEUPDATEWASMADEMS");
        const snapshotsToUpdate = (
          await h.SoccerStatSnapshot.query({
            where: [{ calendarEntryId: ["==", p.calendarEntryPartial.id] }]
          })
        ).docs;
        for (let j = 0; j < snapshotsToUpdate.length; j++) {
          batchTasks.push(
            await h.SoccerStatSnapshot.update(
              {
                id: snapshotsToUpdate[j].id,
                doc: {
                  teamIdWithSquad: common__generateTeamIdWithSquad(snapshotsToUpdate[j].teamId, p.calendarEntryPartial.squad)
                }
              },
              {
                returnBatchTask: true
              }
            )
          );
        }
      }

      batchTasks.push(
        await h.SoccerGame.update(
          {
            id: soccerGame.id,
            doc: soccerGameUpdates
          },
          {
            returnBatchTask: true
          }
        )
      );
    }
  }

  await h._BatchRunner.executeBatch(batchTasks);
  // SERVER_ONLY_TOGGLE
}

calendarEntry__server__update.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
