import { getUniversalHelpers } from "../../helpers";
import { CalendarEntry__PlayerAttendance } from "@ollie-sports/models";

export async function calendarEntry__client__setCoachAttendanceComing(p: {
  calendarEntryId: string;
  coachAccountId: string;
  attendance: Partial<CalendarEntry__PlayerAttendance>;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.CalendarEntry.update({
    id: p.calendarEntryId,
    doc: { coachesAttendace: { [p.coachAccountId]: p.attendance } }
  });
}

// i18n certified - complete
