import { CalendarEntryGameScrimmage, CALENDAR_ENTRY_TYPES } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { SimpleQuery } from "@ollie-sports/firebase-lift";
import { BifrostSubscription } from "@ollie-sports/react-bifrost";
import moment from "moment";
import { MOMENT_DATE_TIME_FORMAT } from "../../constants";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function calendarEntry__client__getImminentGamesSubscription(p: {
  teamIds: string[];
}): BifrostSubscription<CalendarEntryGameScrimmage[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const queries: SimpleQuery<CalendarEntryGameScrimmage>[] = [];

  p.teamIds.forEach(teamId => {
    queries.push({
      where: [
        { teamId: ["==", teamId] },
        { calendarEntryType: ["in", [CALENDAR_ENTRY_TYPES.game, CALENDAR_ENTRY_TYPES.scrimmage]] } as any,
        {
          startDateTime: [">=", moment().startOf("day").format(MOMENT_DATE_TIME_FORMAT)]
        },
        {
          startDateTime: ["<=", moment().endOf("day").format(MOMENT_DATE_TIME_FORMAT)]
        }
      ],
      orderBy: [{ dir: "desc", pathObj: { startDateTime: true } }]
    });
  });

  return firestoreLiftQuerySubToBifrostSub<CalendarEntryGameScrimmage>(
    h.CalendarEntry.multiQuerySubscription({
      queries,
      mergeProcess: { orderBy: { sortKey: "startDateTime", dir: "asc" } }
    }) as any
  );
}

// i18n certified - complete
