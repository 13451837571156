import { CalendarEntry } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import moment = require("moment");
import RRule, { Weekday } from "rrule";
import { generatePushID } from "../../internal-utils/firebaseId";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";
import { common__generateTeamIdWithSquad } from "../common.api";
import { MOMENT_DATE_TIME_FORMAT } from "../../constants/Time";
import { BatchTask } from "@ollie-sports/firebase";
import { isGameScrimmage } from "../..";

export async function calendarEntry__server__create(p: {
  calendarEntry: Omit<CalendarEntry, "id" | "attendance" | "createdAtMS" | "createdAtServerTimestamp">;
  repeatEventInfo?: {
    repeatDaysOfWeek: number[];
    endRepeatDateTime: string;
  };
}): Promise<
  | { success: false; errorMessage: string }
  | {
      success: true;
      newCalendarEntryId: string;
      newRecurrenceSetId?: string;
    }
> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  if (p.calendarEntry.arrivalDateTime && isNaN(Date.parse(p.calendarEntry.arrivalDateTime))) {
    delete p.calendarEntry.arrivalDateTime;
  }

  let datesToCreate = [moment(p.calendarEntry.startDateTime).valueOf()];

  const startDate = p.calendarEntry.startDateTime.match(/.+T/)?.[0];

  if (!startDate) {
    throw new Error("Start date does not have date. This should never happen");
  }

  if (moment(p.calendarEntry.endDateTime).isBefore(p.calendarEntry.startDateTime)) {
    return { success: false, errorMessage: "End time must be after start time" };
  }

  if (p.calendarEntry.arrivalDateTime) {
    p.calendarEntry.arrivalDateTime = p.calendarEntry.arrivalDateTime.replace(/.+T/, startDate);

    if (moment(p.calendarEntry.arrivalDateTime).isAfter(p.calendarEntry.startDateTime)) {
      return { success: false, errorMessage: "Arrival time must be before start time" };
    }
  }

  if (p.repeatEventInfo) {
    //The rrule library thinks Monday is the first day of the week...
    const mapping: Record<number, Weekday> = {
      0: RRule.SU,
      1: RRule.MO,
      2: RRule.TU,
      3: RRule.WE,
      4: RRule.TH,
      5: RRule.FR,
      6: RRule.SA
    };

    const byweekday = p.repeatEventInfo.repeatDaysOfWeek.map(d => mapping[d]);

    const rrule = new RRule({
      freq: RRule.WEEKLY,
      byweekday,
      dtstart: moment(p.calendarEntry.startDateTime)
        .add(1, "day") //Add 1 day since we create the original date above
        .toDate(),
      until: moment(p.repeatEventInfo.endRepeatDateTime).endOf("day").toDate()
    });

    datesToCreate = datesToCreate.concat(rrule.all().map(d => d.getTime()));
  }

  const recurrenceSetId = p.repeatEventInfo ? generatePushID() : "";

  const absTimeDiff = {
    endDateTime: Math.abs(moment(p.calendarEntry.startDateTime).diff(moment(p.calendarEntry.endDateTime))),
    arrivalDateTime: Math.abs(moment(p.calendarEntry.startDateTime).diff(moment(p.calendarEntry.arrivalDateTime)))
  };

  const itemsToCreatePre = datesToCreate.map(startMS => ({
    ...p.calendarEntry,
    id: h.CalendarEntry.generateId(),
    attendance: {},
    createdAtMS: Date.now(),
    calendarEntryType: p.calendarEntry.calendarEntryType as any, //Dumb. Way of telling typescript its okay.
    startDateTime: moment(startMS).format(MOMENT_DATE_TIME_FORMAT),
    endDateTime: moment(startMS + absTimeDiff.endDateTime).format(MOMENT_DATE_TIME_FORMAT),
    ...(p.calendarEntry.arrivalDateTime
      ? { arrivalDateTime: moment(startMS - absTimeDiff.arrivalDateTime).format(MOMENT_DATE_TIME_FORMAT) }
      : {}),
    ...(p.repeatEventInfo ? { recurrenceSetId } : {})
  }));

  const itemsToCreate = itemsToCreatePre.map(c => {
    c.teamIdWithSquad = common__generateTeamIdWithSquad(c.teamId, c.squad);
    return c;
  });

  if (itemsToCreate.length === 0) {
    return { success: false, errorMessage: "No events would be created with the information you have provided." };
  }

  const batchTasks: BatchTask[] = await Promise.all(
    itemsToCreate.map(async doc => await h.CalendarEntry.add({ doc }, { returnBatchTask: true }))
  );

  if (isGameScrimmage(p.calendarEntry) && p.calendarEntry.jerseyColor) {
    batchTasks.push(
      await h.Team.update(
        {
          id: p.calendarEntry.teamId,
          doc: { defaultJerseyColors: { [p.calendarEntry.gameVenue]: p.calendarEntry.jerseyColor } }
        },
        { returnBatchTask: true }
      )
    );
  }

  await h._BatchRunner.executeBatch(batchTasks);

  return {
    success: true,
    newCalendarEntryId: itemsToCreate[0].id,
    ...(recurrenceSetId ? { newRecurrenceSetId: recurrenceSetId } : {})
  };
  // SERVER_ONLY_TOGGLE
}

calendarEntry__server__create.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
