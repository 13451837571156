import { CalendarEntry } from "@ollie-sports/models";
import express from "express";
import { getUniversalHelpers } from "../../helpers";
import moment from "moment";
import { validateToken } from "../../internal-utils/server-auth";
import { MOMENT_DATE_TIME_FORMAT } from "../../constants/Time";

export async function calendarEntry__server__updateRecurring(p: {
  calendarEntryPartial: Partial<CalendarEntry>;
  recurrenceSetId: string;
  updateAfterDateTime: string;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const calendarEntries = await h.CalendarEntry.query({
    where: [{ recurrenceSetId: ["==", p.recurrenceSetId] }, { startDateTime: [">=", p.updateAfterDateTime] }]
  });

  const timeToAdd = {
    arrivalDateTime: p.calendarEntryPartial.arrivalDateTime
      ? moment(p.calendarEntryPartial.arrivalDateTime).diff(moment(p.calendarEntryPartial.arrivalDateTime).startOf("day"))
      : 0,
    startDateTime: p.calendarEntryPartial.startDateTime
      ? moment(p.calendarEntryPartial.startDateTime).diff(moment(p.calendarEntryPartial.startDateTime).startOf("day"))
      : 0,
    endDateTime: p.calendarEntryPartial.endDateTime
      ? moment(p.calendarEntryPartial.endDateTime).diff(moment(p.calendarEntryPartial.endDateTime).startOf("day"))
      : 0
  };

  const batchTasks = await Promise.all(
    calendarEntries.docs.map(item => {
      const startOfDayMS = moment(item.startDateTime).startOf("day").valueOf();

      const updateItem = {
        ...p.calendarEntryPartial,
        ...(p.calendarEntryPartial.startDateTime
          ? { startDateTime: moment(startOfDayMS + timeToAdd.startDateTime).format(MOMENT_DATE_TIME_FORMAT) }
          : {}),
        ...(p.calendarEntryPartial.endDateTime
          ? { endDateTime: moment(startOfDayMS + timeToAdd.endDateTime).format(MOMENT_DATE_TIME_FORMAT) }
          : {}),
        ...(p.calendarEntryPartial.arrivalDateTime
          ? { arrivalDateTime: moment(startOfDayMS + timeToAdd.arrivalDateTime).format(MOMENT_DATE_TIME_FORMAT) }
          : {})
      };

      if (updateItem.arrivalDateTime && isNaN(Date.parse(updateItem.arrivalDateTime))) {
        delete updateItem.arrivalDateTime;
      }

      return h.CalendarEntry.update({ id: item.id, doc: updateItem });
    })
  );

  await h._BatchRunner.executeBatch(batchTasks);
  // SERVER_ONLY_TOGGLE
}

calendarEntry__server__updateRecurring.auth = async (r: express.Request) => {
  await validateToken(r);
};
// i18n certified - complete
