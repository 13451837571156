import { CalendarEntry, CalendarEntryGameScrimmage, SoccerStatModes } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { BifrostSubscription } from "@ollie-sports/react-bifrost";
import { SimpleQuery } from "@ollie-sports/firebase-lift";
import moment from "moment";
import { MOMENT_DATE_TIME_FORMAT } from "../../constants";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function calendarEntry__client__getRecentGamesSubscription(p: {
  teamIds: string[];
}): BifrostSubscription<CalendarEntryGameScrimmage[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const pendingQueries: SimpleQuery<CalendarEntry>[] = [];
  p.teamIds.forEach(teamId => {
    pendingQueries.push({
      where: [
        { teamId: ["==", teamId] },
        { derived: { endGameDetails: { source: ["in", [SoccerStatModes.team, SoccerStatModes.individual]] } } },
        {
          startDateTime: [">=", moment().startOf("day").subtract(90, "days").format(MOMENT_DATE_TIME_FORMAT)]
        }
      ],
      limit: 3,
      orderBy: [{ dir: "desc", pathObj: { startDateTime: true } }]
    });
  });

  return firestoreLiftQuerySubToBifrostSub<CalendarEntryGameScrimmage>(
    h.CalendarEntry.multiQuerySubscription({
      queries: pendingQueries,
      mergeProcess: { orderBy: { sortKey: "startDateTime", dir: "desc" } }
    }) as any
  );
}

// i18n certified - complete
