import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import _ from "lodash";
import moment from "moment";
import { MOMENT_DATE_TIME_FORMAT } from "../../constants";
import { CalendarEntry, CalendarEntryGameScrimmage, OrgId, SoccerStatModes } from "@ollie-sports/models";
import express from "express";
import { validateToken } from "../../internal-utils/server-auth";

export async function calendarEntry__server__getGamesWithStatsForOrg(p: {
  orgId: OrgId;
  nextOffset?: number;
}): Promise<{ items: CalendarEntryGameScrimmage[]; nextOffset?: any }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const q1 = p.orgId;
  const queryParams = [q1];

  const query = `select c.item
  from mirror_calendarentry c,
       mirror_team t
  where t.id = c.item ->> 'teamId'
    and t.item ->> 'orgId' = $1
    and c.item -> 'derived' -> 'endGameDetails' ->> 'source' = any ('{${SoccerStatModes.team},${SoccerStatModes.individual}}')
        order by c.c_start_date_truncated_ms desc limit 25 ${p.nextOffset ? `offset ${p.nextOffset}` : ""};`;

  const r1 = await getServerHelpers().getAppPgPool().query(query, queryParams);

  const items = r1.rows.map(row => row.item as CalendarEntryGameScrimmage) as CalendarEntryGameScrimmage[];

  return {
    items,
    nextOffset: items.length < 25 ? undefined : (p.nextOffset ?? 0) + 25
  };
  // SERVER_ONLY_TOGGLE
}

calendarEntry__server__getGamesWithStatsForOrg.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

// i18n certified - complete
