import _ from "lodash";
import { Team, CalendarEntry } from "@ollie-sports/models";
import moment from "moment-timezone";

export function filterAndOrderCalendarEvents(p: { type: "upcoming" | "past"; data: CalendarEntry[]; timezone?: string }) {
  let result = p.data;

  const cutoff = moment()
    .tz(p.timezone ?? moment.tz.guess())
    .valueOf();

  return _(result)
    .map(c => ({
      timestamp: moment.tz(c.endDateTime, c.timezone).valueOf(),
      data: c
    }))
    .filter(c => (p.type === "past" ? c.timestamp < cutoff : c.timestamp >= cutoff))
    .orderBy(c => c.timestamp, p.type === "past" ? "desc" : "asc")
    .map(a => a.data)
    .value();
}
