import { getUniversalHelpers } from "../../helpers";
import { CalendarEntryGameScrimmageEndGameDetails } from "@ollie-sports/models";

export async function calendarEntry__client__setEndGameDetails(p: {
  calendarEntryId: string;
  endGameDetails: CalendarEntryGameScrimmageEndGameDetails;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.CalendarEntry.update({
    id: p.calendarEntryId,
    doc: { derived: { endGameDetails: p.endGameDetails } }
  });
}

// i18n certified - complete
