import { BifrostSubscription, createBifrostSubscription } from "@ollie-sports/react-bifrost";
import { CalendarEntry, Team, TeamId, OrgId, EventFilterSettings, Org } from "@ollie-sports/models";
import { getUniversalHelpers, getServerHelpers } from "../../helpers";
import moment from "moment-timezone";
import _ from "lodash";
import { validateToken } from "../../internal-utils/server-auth";
import { MOMENT_DATE_TIME_FORMAT } from "../../constants/Time";
import { getQueryFragmentToFilterEvents } from "./utils/getQueryFragmentToFilterEvents";
import { filterAndOrderCalendarEvents } from "./utils/filterEvents";

export async function calendarEntry__server__upcomingOrgFetch(p: {
  filterSettings: EventFilterSettings;
  defaultOrgId?: OrgId;
  timezone: string;
  orgs: Org[];
}): Promise<{ calendarEntries: CalendarEntry[]; teams: Team[]; orgs: Org[] }> {
  // SERVER_ONLY_TOGGLE
  const orgIdToUse = p.filterSettings.orgIdActivated ?? p.defaultOrgId ?? "";
  if (!orgIdToUse) {
    return { calendarEntries: [], teams: [], orgs: [] };
  }

  const org = p.orgs.find(o => (o.id = orgIdToUse));

  const filterQuery = getQueryFragmentToFilterEvents(p.filterSettings);

  //TODO: Fix timezone bug, Note: Have to subtract 24 hours to the query cutoff b/c we store strings, not timestamps
  let r1 = await getServerHelpers()
    .getAppPgPool()
    .query(
      `select c.item calendarEntry,
         t.item team
         from mirror_calendarentry c  left join mirror_team t
         on c.item->>'teamId' = t.id
           where ((t.item->>'orgId' = $1 ${filterQuery}) or c.item->>'orgEventOrgId' = $1)
    and to_date(substr(c.item->>'endDateTime',0,11), 'YYYY-MM-DD') > NOW() - INTERVAL '24 hours'
    order by c.item->>'startDateTime' asc
    limit 200;`,
      [orgIdToUse]
    );

  return {
    calendarEntries: filterAndOrderCalendarEvents({
      data: r1.rows.map(r => r.calendarentry),
      timezone: p.timezone,
      type: "upcoming"
    }),
    teams: _(r1.rows)
      .map(r => r.team)
      .compact()
      .uniqBy(t => t.id)
      .value(),
    orgs: org ? [org] : []
  };

  // SERVER_ONLY_TOGGLE
}

calendarEntry__server__upcomingOrgFetch.auth = async (req: any) => {
  await validateToken(req);
};

// i18n certified - complete
