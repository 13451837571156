import { AccountId, CalendarEntry, OrgId, ORG_EVENT_ROLE_GROUPS } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import _ from "lodash";

export async function calendarEntry__server__getAccountIdsForOrgEvent(p: { calendarEntry: CalendarEntry }): Promise<AccountId[]> {
  // SERVER_ONLY_TOGGLE
  if (!p.calendarEntry.orgEventOrgId || !p.calendarEntry.orgEventRoleGroup) {
    return [];
  }

  const q1 = p.calendarEntry.orgEventOrgId;
  const queryParams = [q1];

  // Club Admins should always be included
  const queries = [`select distinct account_id from v_org_accounts where org_id = $1;`];

  switch (p.calendarEntry.orgEventRoleGroup) {
    case ORG_EVENT_ROLE_GROUPS.all:
      queries.push(`select distinct account_id from v_team_accounts where org_id = $1`);
      break;
    case ORG_EVENT_ROLE_GROUPS.clubAdmins:
      // No team query needed
      break;
    case ORG_EVENT_ROLE_GROUPS.coaches:
      queries.push(
        `select distinct account_id from v_team_accounts where org_id = $1 and (team_account->>'staffTitle' = 'headCoach' or team_account->>'staffTitle' = 'assistantCoach');`
      );
      break;
    case ORG_EVENT_ROLE_GROUPS.headCoaches:
      queries.push(
        `select distinct account_id from v_team_accounts where org_id = $1 and team_account->>'staffTitle' = 'headCoach';`
      );
      break;
    case ORG_EVENT_ROLE_GROUPS.teamAdmins:
      queries.push(
        `select distinct account_id from v_team_accounts where org_id = $1 and team_account->>'staffTitle' = 'teamAdmin';`
      );
      break;
    case ORG_EVENT_ROLE_GROUPS.teamStaff:
      queries.push(
        `select distinct account_id from v_team_accounts where org_id = $1 and team_account->>'staffTitle' is not null;`
      );
      break;
  }

  const results = await Promise.all(
    queries.map(query => {
      return new Promise<string[]>(async resolve => {
        const aIds = (await getServerHelpers().getAppPgPool().query(query, queryParams)).rows.map(
          row => row["account_id"]
        ) as string[];
        resolve(aIds);
      });
    })
  );

  const accountsIds = results.reduce((acc, val) => {
    val.forEach(accountId => {
      acc.push(accountId);
    });
    return acc;
  }, [] as string[]);

  return _.uniq(accountsIds);

  // SERVER_ONLY_TOGGLE
}

calendarEntry__server__getAccountIdsForOrgEvent.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

// i18n certified - complete
