import { AccountId, CalendarEntryId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";

export async function calendarEntry__server__addAttendanceReminder(p: {
  accountId: AccountId;
  calendarEntryId: CalendarEntryId;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.CalendarEntry.update({
    id: p.calendarEntryId,
    doc: { attendanceRemindersSendersBySendMSString: { [Date.now()]: p.accountId } }
  });
  // SERVER_ONLY_TOGGLE
}

calendarEntry__server__addAttendanceReminder.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
