import { AccountId, CalendarEntry } from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";

export async function calendarEntry__server__getEventsByDateRange(p: {
  teamIdWithSquads: string[];
  startDateMS: number;
  endDateMS: number;
}): Promise<{ events: CalendarEntry[] }> {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool } = getServerHelpers();

  await new Promise<void>(r => setTimeout(() => r(), 2000));

  const r1 = await getAppPgPool().query(
    `
select item
from mirror_calendarentry c
where c.item ->> 'teamIdWithSquad' = any ($1::text[])
  and c.c_start_date_truncated_ms between $2 and $3;`,
    [p.teamIdWithSquads, p.startDateMS, p.endDateMS]
  );

  const events = r1.rows.map(r => r.item as CalendarEntry);

  return { events };
  // SERVER_ONLY_TOGGLE
}

calendarEntry__server__getEventsByDateRange.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
