import { getUniversalHelpers } from "../../helpers";
import _ from "lodash";
import moment from "moment";
import { MOMENT_DATE_TIME_FORMAT } from "../../constants";
import { CalendarEntry, CalendarEntryGameScrimmage, SoccerStatModes } from "@ollie-sports/models";

export async function calendarEntry__client__getGamesWithStats(p: {
  teamIds: string[];
  nextQuery?: any;
}): Promise<{ items: CalendarEntryGameScrimmage[]; nextQuery?: any }> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  if (p.nextQuery) {
    const result = await h.CalendarEntry.query(p.nextQuery);

    return {
      items: result.docs as CalendarEntryGameScrimmage[],
      nextQuery: result.nextQuery
    };
  }

  if (p.teamIds.length > 1) {
    const results = await Promise.all(
      p.teamIds.map(teamId =>
        h.CalendarEntry.query({
          where: [
            { teamId: ["==", teamId] },
            { derived: { endGameDetails: { source: ["in", [SoccerStatModes.team, SoccerStatModes.individual]] } } },
            {
              startDateTime: [">", moment().startOf("day").subtract(90, "days").format(MOMENT_DATE_TIME_FORMAT)]
            }
          ],
          orderBy: [{ dir: "desc", pathObj: { startDateTime: true } }]
        })
      )
    );

    const games = _.flatten(results.map(r => r.docs as CalendarEntryGameScrimmage[]));

    return {
      items: _.orderBy(games, "startDateTime", "desc"),
      nextQuery: undefined
    };
  } else if (p.teamIds.length === 1) {
    const results = await h.CalendarEntry.query({
      where: [
        { teamId: ["==", p.teamIds[0]] },
        { derived: { endGameDetails: { source: ["in", [SoccerStatModes.team, SoccerStatModes.individual]] } } }
      ],
      limit: 25,
      orderBy: [{ dir: "desc", pathObj: { startDateTime: true } }]
    });

    return {
      items: _.orderBy(results.docs as CalendarEntryGameScrimmage[], "startDateTime", "desc"),
      nextQuery: results.nextQuery
    };
  } else {
    return {
      items: [],
      nextQuery: undefined
    };
  }
}

// i18n certified - complete
