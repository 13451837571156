import { getUniversalHelpers } from "../../helpers";
import express from "express";

import { validateToken } from "../../internal-utils/server-auth";
import { BatchTask } from "@ollie-sports/firebase";
import _ from "lodash";

export async function calendarEntry__server__delete(
  p: { type: "single"; calendarEntryId: string } | { type: "recurring"; recurrenceSetId: string; deleteAfterDateTime: string }
) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let calendarEntryIds: string[] = [];

  if (p.type === "single") {
    calendarEntryIds = [p.calendarEntryId];
  } else {
    calendarEntryIds = (
      await h.CalendarEntry.query({
        where: [{ recurrenceSetId: ["==", p.recurrenceSetId] }, { startDateTime: [">=", p.deleteAfterDateTime] }]
      })
    ).docs.map(c => c.id);
  }

  await Promise.all(
    calendarEntryIds.map(async id => {
      const games = await h.SoccerGame.query({ where: [{ calendarEntryId: ["==", id] }] });
      const batchTasks: BatchTask[] = [await h.CalendarEntry.delete({ id }, { returnBatchTask: true })];

      for (let j = 0; j < games.docs.length; j++) {
        const soccerGameId = games.docs[j].id;
        batchTasks.push(await h.SoccerGame.delete({ id: soccerGameId }, { returnBatchTask: true }));

        const [soccerGameEvents, soccerGameEventBundles, soccerStatSnapshots] = await Promise.all([
          h.SoccerGameEvent.query({ where: [{ soccerGameId: ["==", soccerGameId] }] }),
          h.SoccerGameEventBundle.query({ where: [{ soccerGameId: ["==", soccerGameId] }] }),
          h.SoccerStatSnapshot.query({ where: [{ soccerGameId: ["==", soccerGameId] }] })
        ]);
        for (let i = 0; i < soccerGameEvents.docs.length; i++) {
          batchTasks.push(await h.SoccerGameEvent.delete({ id: soccerGameEvents.docs[i].id }, { returnBatchTask: true }));
        }
        for (let i = 0; i < soccerGameEventBundles.docs.length; i++) {
          batchTasks.push(
            await h.SoccerGameEventBundle.delete({ id: soccerGameEventBundles.docs[i].id }, { returnBatchTask: true })
          );
        }
        for (let i = 0; i < soccerStatSnapshots.docs.length; i++) {
          batchTasks.push(await h.SoccerStatSnapshot.delete({ id: soccerStatSnapshots.docs[i].id }, { returnBatchTask: true }));
        }
      }

      const chunks = _.chunk(batchTasks, 400);
      for (let j = 0; j < chunks.length; j++) {
        await h._BatchRunner.executeBatch(chunks[j]);
      }
    })
  );
  // SERVER_ONLY_TOGGLE
}

calendarEntry__server__delete.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
