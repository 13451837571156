import { BifrostSubscription } from "@ollie-sports/react-bifrost";

import { CalendarEntry } from "@ollie-sports/models";

import { getUniversalHelpers } from "../../helpers";

import { firestoreLiftDocSubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function calendarEntry__client__byIdSubscription(p: {
  calendarEntryId: string;
}): BifrostSubscription<CalendarEntry | null> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return firestoreLiftDocSubToBifrostSub(h.CalendarEntry.docSubscription(p.calendarEntryId));
}

// i18n certified - complete
