import { CalendarEntry, TeamId } from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";

export async function calendarEntry__server__recentOpponents(p: { teamId: TeamId }): Promise<string[]> {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool } = getServerHelpers();

  const query = `select distinct item ->> 'opponentName' as opp_name
  from mirror_calendarentry
  where item ->> 'teamId' = '${p.teamId}'
    and item ->> 'opponentName' is not null
    and to_timestamp(cast(item ->> 'createdAtMS' as numeric) / 1000) > (now() - interval '1 years');`;

  return (await getAppPgPool().query(query)).rows.map(a => a.opp_name);
  // SERVER_ONLY_TOGGLE
}

calendarEntry__server__recentOpponents.auth = async (req: express.Request) => {
  await validateToken(req);
};

// i18n certified - complete
